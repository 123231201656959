.table {
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
}

.table-row {
	display: flex;
	padding: 1rem 1rem;
	justify-content: space-between;
}
