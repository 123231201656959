.expand-toggle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #212529;
	padding: 0 1rem;
	font-size: 1.2rem;
	width: 100%;
}

.expand-toggle-icon {
	font-size: 3.6rem;
}
