.tab-group {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 0;
}

@media (max-width: 480px) {
	.tab-group {
		flex-direction: column;
		align-items: center;
	}
}
